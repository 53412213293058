import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  toggleDosageSelectionModal,
  getPreviouslyAddedMedications,
  setSelectedMedicationItem,
} from '@vezeeta/enterprise-store/lib/actions/Pharma';
import { status } from '@vezeeta/enterprise-utils/lib/enum';
import { Spinner } from '@vezeeta/web-components';
import { MIXPANEL_EVENTS } from 'app/mixpanelEventsCatelog';
import MedicationPrevItem from '../MedicationPrevItem/MedicationPrevItem';
import {
  AddedMedicationsContainer,
  AddedMedicationsTitle,
  MedicationsList,
  StatusContainer,
  StatusContainerWrapper,
  ShowAllLinkContainer,
  ShowAllLinkWrapper,
} from './MedicationPrevItems.style';

const MedicationPrevItems = ({
  localization,
  language,
  prevAddedMedicationsLoadState,
  prevAddedMedications,
  presciptionMedicationsList,
  reservationParams,
  mixpanel,
  ...props
}) => {
  useEffect(() => {
    props.getPreviouslyAddedMedications();
  }, []);

  const [isShownAll, toggleShowAll] = useState(false);

  const renderMedicationList = () => {
    if (
      prevAddedMedicationsLoadState === status.SHOULD_CALL_API ||
      prevAddedMedicationsLoadState === status.FETCHING
    ) {
      return (
        <StatusContainer>
          <StatusContainerWrapper>
            <Spinner radius={40} />
          </StatusContainerWrapper>
        </StatusContainer>
      );
    }
    if (
      prevAddedMedications &&
      prevAddedMedications.previouslyAddedList &&
      prevAddedMedications.previouslyAddedList.length
    ) {
      const itemsWithoutCorruptions = prevAddedMedications.previouslyAddedList.filter(
        med => med.name,
      );
      const isNotAddedItems = [];
      itemsWithoutCorruptions.forEach(item => {
        let isFound = false;
        presciptionMedicationsList.forEach(med => {
          if (med.productKey === item.key) isFound = true;
        });
        if (!isFound) isNotAddedItems.push(item);
      });

      let renderedItemsList;
      if (isShownAll) {
        renderedItemsList = isNotAddedItems;
      } else {
        renderedItemsList = isNotAddedItems.slice(0, 3);
      }

      if (renderedItemsList && renderedItemsList.length > 0) {
        return (
          <div>
            <AddedMedicationsContainer>
              <AddedMedicationsTitle>{localization.PreviouslyAdded}</AddedMedicationsTitle>
              <MedicationsList>
                {renderedItemsList.map((medication, idx) => {
                  const isAdded = presciptionMedicationsList.find(
                    med => med.productKey === medication.key,
                  );

                  if (!isAdded) {
                    const timeOfDayIds = [];
                    const timeOfDayName = [];
                    if (
                      medication.settings[0].timesOfDay &&
                      medication.settings[0].timesOfDay.length
                    ) {
                      medication.settings[0].timesOfDay.forEach(timeOfDay => {
                        timeOfDayName.push(timeOfDay.name);
                        timeOfDayIds.push(timeOfDay.id);
                      });
                    }

                    const item = {
                      dosageCount: medication.settings[0].dosageQuantity,
                      durationCount: medication.settings[0].duration,
                      productName: medication.name,
                      productShapeIconUrl: medication.imageUrl,
                      productKey: medication.key,
                      dosageName: medication.settings[0].dosageUnitName,
                      durationName: medication.settings[0].durationUnitName,
                      frequencyName: medication.settings[0].frequencyName,
                      instructionName: medication.settings[0].instructionName,
                      timeOfDayName,
                    };

                    const trackMixpanelOnClickAddItem = payload => {
                      mixpanel.track(MIXPANEL_EVENTS.ADD_MEDICATION, {
                        ...payload,
                        Source:
                          reservationParams &&
                          reservationParams.source &&
                          reservationParams.source === 'schedule'
                            ? 'schedule/Previously added medications'
                            : 'Patient Profile/Previously added medications',
                      });
                    };

                    const appendMedicationItem = () => {
                      trackMixpanelOnClickAddItem({
                        SelectedMedications: medication.name,
                      });

                      props.setSelectedMedicationItem({
                        dosageCount: medication.settings[0].dosageQuantity,
                        dosageUnitId: medication.settings[0].dosageUnitId,
                        durationCount: medication.settings[0].duration,
                        durationUnitId: medication.settings[0].durationUnitId,
                        instructionId: medication.settings[0].instructionId,
                        timeOfDayIds,
                        frequencyIds: medication.settings[0].frequencyId,
                        additionalNote: medication.settings[0].notes,
                        dosageName: medication.settings[0].dosageUnitName,
                        frequencyName: medication.settings[0].frequencyName,
                        timeOfDayName,
                        instructionName: medication.settings[0].instructionName,
                        durationName: medication.settings[0].durationUnitName,
                        productKey: medication.key,
                        productShapeIconUrl: medication.imageUrl,
                        productName: medication.name,
                        activeIngrediant: medication.activeIngredient,
                        settings: medication.settings,
                        id: medication.id,
                      });
                      props.toggleDosageSelectionModal(true);
                    };

                    return (
                      <MedicationPrevItem
                        listLength={itemsWithoutCorruptions.length}
                        localization={localization}
                        index={idx}
                        item={item}
                        language={language}
                        appendMedicationItemAction={appendMedicationItem}
                        presciptionMedicationsList={presciptionMedicationsList}
                      />
                    );
                  }
                  return '';
                })}
              </MedicationsList>
              {!isShownAll && isNotAddedItems.length > 3 && (
                <ShowAllLinkContainer onClick={() => toggleShowAll(true)}>
                  <ShowAllLinkWrapper>{localization.showAllPreviousMeds}</ShowAllLinkWrapper>
                </ShowAllLinkContainer>
              )}
            </AddedMedicationsContainer>
          </div>
        );
      }
    }
    return '';
  };

  return <div>{renderMedicationList()}</div>;
};

const mapStateToProps = state => ({
  prevAddedMedications: state.pharma.prevAddedMedications,
  prevAddedMedicationsLoadState: state.pharma.prevAddedMedicationsLoadState,
  presciptionMedicationsList: state.pharma.presciptionMedicationsList,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleDosageSelectionModal,
      getPreviouslyAddedMedications,
      setSelectedMedicationItem,
    },
    dispatch,
  );

MedicationPrevItems.propTypes = {
  getPreviouslyAddedMedications: PropTypes.func,
  setSelectedMedicationItem: PropTypes.func,
  localization: PropTypes.object,
  language: PropTypes.string,
  prevAddedMedicationsLoadState: PropTypes.string,
  prevAddedMedications: PropTypes.object,
  toggleDosageSelectionModal: PropTypes.func,
  presciptionMedicationsList: PropTypes.array,
  reservationParams: PropTypes.object,
  mixpanel: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicationPrevItems);
