export const getDosageListForDropDown = (list, language) => {
  const dropDownList = [];
  if (list && list.length) {
    list.forEach(item => {
      let itemName;
      const localizedUnitObject = item.dosageUnitTranslators.filter(unit => unit.lang === language);
      if (localizedUnitObject && localizedUnitObject[0]) itemName = localizedUnitObject[0].name;
      dropDownList.push({
        fieldValue: itemName,
        value: item.key,
        key: item.key,
      });
    });
  }
  return dropDownList;
};

export const getDurationsListForDropDown = (list, language) => {
  const dropDownList = [];
  if (list && list.length) {
    list.forEach(item => {
      let itemName;
      const localizedObject = item.durationUnitTranslators.filter(
        duration => duration.lang === language,
      );
      if (localizedObject && localizedObject[0]) itemName = localizedObject[0].name;
      dropDownList.push({
        fieldValue: itemName,
        value: item.key,
        key: item.key,
      });
    });
  }
  return dropDownList;
};
