import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Spinner,
  NotificationBar,
  Field,
  Icon,
  NewButton,
  Paragraph,
  IconsStore,
} from '@vezeeta/web-components';
import {
  getPrescriptionSettings,
  addMedicationToPrescriptionList,
  setSelectedMedicationItem,
} from '@vezeeta/enterprise-store/lib/actions/Pharma';
import { language } from '@vezeeta/enterprise-store/lib/types/language';
import ErrorImg from 'app/assets/images/error.png';
import { status, prescriptionItemTypesEnum } from '@vezeeta/enterprise-utils/lib/enum';
import Icons from '@vezeeta/web-icons';
import Counter from './Counter';
import {
  ModalContainer,
  InnerPopupContainer,
  IconContainer,
  HeaderTitle,
  PopUpHeader,
  CloseLink,
  Container,
  SaveButton,
  ButtonContainer,
  CloseIcon,
  ProductContainer,
  ProductDetailsContainer,
  ProductDetailsDescription,
  ProductDetailsName,
  ProductDetailsWrapper,
  ProductImg,
  ProductWrapper,
  FieldTitle,
  OptionItem,
  OptionItemContainer,
  OptionItemWrapper,
  OptionsContainer,
  SectionContainer,
  SeeMoreContainer,
  SeeMoreItem,
  SeeMoreWrapper,
  ExtendInputFieldsStyle,
  ExtendDropDownStyle,
  StatusContainer,
  StatusContainerWrapper,
  ErrorImage,
  ErrorMessage,
  ErrorMessageContainer,
  OptionalLabel,
  RequiredLabel,
  SettingContainer,
  SettingDescripMain,
  SettingDescripContainer,
  SettingDescripSub,
  SettingDescripWrapper,
  SettingSelectionContainer,
  SettingSelectionWrapper,
  SettingWrapper,
  CheckCircle,
  extendExportButton,
  BackButton,
  InnerContainer,
  ExtendCounterStyle,
} from './DosageSelectionModal.styles';

import {
  getDosageListForDropDown,
  getDurationsListForDropDown,
} from './DosageSelectionModal.helper';

const FIELDS_ERRORS = {
  VALID_FIELDS: 0,
  REQUIRED_FIELDS_ERROR: 1,
  NUMERICAL_FIELDS_ERROR: 2,
  ADD_SERVICE: 3,
};

const SETTINGS = {
  PREV_DOSAGE_SETTINGS: 0,
  SET_DOSAGE_SETTINGS: 1,
};

const DosageSelectionModal = ({
  onCloseAction,
  localization,
  showDosageSelectionModal,
  selectedMedicationItemDetails,
  prescriptionSettings,
  prescriptionSettingsLoadState,
  ...props
}) => {
  const [stepIndex, setStepIndex] = useState(SETTINGS.PREV_DOSAGE_SETTINGS);
  const [selectedSetting, setSelectedSetting] = useState(0);
  const [isValidDosageForm, setDosageFormValidation] = useState(true);
  const [showMoreFrequency, toggleShowMoreFrequency] = useState(false);
  const [showMoreTimeOfDay, toggleShowMoreTimeOfDay] = useState(false);
  const [showMoreInstructions, toggleShowMoreInstructions] = useState(false);
  const [dosageCount, setDosageCount] = useState(1);
  const [dosageUnitId, setDosageUnitId] = useState(undefined);
  const [durationCount, setDurationCount] = useState(7);
  const [durationUnitId, setDurationUnitId] = useState(undefined);
  const [instructionId, setInstructionId] = useState(undefined);
  const [timeOfDayIds, setTimeOfDayIds] = useState([]);
  const [frequencyIds, setFrequencyIds] = useState(undefined);
  const [additionalNote, setAdditionalNote] = useState(undefined);
  const [dosageName, setDosageName] = useState(undefined);
  const [frequencyName, setFrequencyName] = useState(undefined);
  const [timeOfDayName, setTimeOfDayName] = useState([]);
  const [instructionName, setInstructionName] = useState(undefined);
  const [durationName, setDurationName] = useState(undefined);
  const [validationIndex, setReservationInfofValidation] = useState(FIELDS_ERRORS.VALID_FIELDS);

  useEffect(() => {
    const defaultDurationID =
      prescriptionSettings &&
      prescriptionSettings.durationUnits &&
      prescriptionSettings.durationUnits[0] &&
      prescriptionSettings.durationUnits[0].key;
    const defaultDurationName =
      prescriptionSettings &&
      prescriptionSettings.durationUnits &&
      prescriptionSettings.durationUnits[0] &&
      prescriptionSettings.durationUnits[0].durationUnitTranslators &&
      prescriptionSettings.durationUnits[0].durationUnitTranslators[0].name;
    const defaultDosageID =
      prescriptionSettings &&
      prescriptionSettings.dosageUnits &&
      prescriptionSettings.dosageUnits[0] &&
      prescriptionSettings.dosageUnits[0].key;
    const defaultDosagenName =
      prescriptionSettings &&
      prescriptionSettings.dosageUnits &&
      prescriptionSettings.dosageUnits[0] &&
      prescriptionSettings.dosageUnits[0].dosageUnitTranslators &&
      prescriptionSettings.dosageUnits[0].dosageUnitTranslators[0].name;
    if (selectedMedicationItemDetails && selectedMedicationItemDetails.productKey) {
      setDosageCount(selectedMedicationItemDetails.dosageCount || 1);
      setDosageUnitId(
        selectedMedicationItemDetails.dosageUnitId
          ? selectedMedicationItemDetails.dosageUnitId
          : defaultDosageID,
      );
      setDurationCount(selectedMedicationItemDetails.durationCount || 7);
      setDurationUnitId(
        selectedMedicationItemDetails.durationUnitId
          ? selectedMedicationItemDetails.durationUnitId
          : defaultDurationID,
      );
      setInstructionId(selectedMedicationItemDetails.instructionId);
      setTimeOfDayIds(selectedMedicationItemDetails.timeOfDayIds || []);
      setFrequencyIds(selectedMedicationItemDetails.frequencyIds);
      setAdditionalNote(selectedMedicationItemDetails.additionalNote);
      setDosageName(
        selectedMedicationItemDetails.dosageName
          ? selectedMedicationItemDetails.dosageName
          : defaultDosagenName,
      );
      setFrequencyName(selectedMedicationItemDetails.frequencyName);
      setTimeOfDayName(selectedMedicationItemDetails.timeOfDayName || []);
      setInstructionName(selectedMedicationItemDetails.instructionName);
      setDurationName(
        selectedMedicationItemDetails.durationName
          ? selectedMedicationItemDetails.durationName
          : defaultDurationName,
      );
    }
  }, [showDosageSelectionModal, selectedMedicationItemDetails, prescriptionSettings]);

  useEffect(() => {
    if (selectedMedicationItemDetails && selectedMedicationItemDetails.productKey) {
      props.getPrescriptionSettings(selectedMedicationItemDetails.productKey);
      if (selectedMedicationItemDetails.settings && selectedMedicationItemDetails.settings.length) {
        setStepIndex(SETTINGS.PREV_DOSAGE_SETTINGS);
      } else {
        setStepIndex(SETTINGS.SET_DOSAGE_SETTINGS);
      }
    }
  }, [showDosageSelectionModal]);

  const resetModalContentAfterSubmitting = () => {
    setSelectedSetting(0);
    setStepIndex(SETTINGS.PREV_DOSAGE_SETTINGS);
    setDosageFormValidation(true);
    toggleShowMoreFrequency(false);
    toggleShowMoreTimeOfDay(false);
    toggleShowMoreInstructions(false);
    setDosageCount(1);
    setDosageUnitId(undefined);
    setDurationCount(7);
    setDurationUnitId(undefined);
    setInstructionId(undefined);
    setTimeOfDayIds([]);
    setFrequencyIds(undefined);
    setAdditionalNote('');
    setDosageName('');
    setFrequencyName('');
    setTimeOfDayName([]);
    setInstructionName('');
    setDurationName('');
    setReservationInfofValidation(FIELDS_ERRORS.VALID_FIELDS);
  };

  const isNotificationBarShown = () => validationIndex !== FIELDS_ERRORS.VALID_FIELDS;

  const getNotificationBarmessage = () => {
    /*  if (addAppointmentDetailsStatus === status.FAIL) return localization.somethingWentWrong;
    if (!validationIndex) return localization.fillInRequiredFieldsToAddAppointment;
    switch (validationIndex) {
      case FIELDS_ERRORS.REQUIRED_FIELDS_ERROR:
        return localization.fillInRequiredFieldsToAddAppointment;
      case FIELDS_ERRORS.NUMERICAL_FIELDS_ERROR:
        return localization.enterValidValues;
      case FIELDS_ERRORS.ADD_SERVICE:
        return localization.pleaseAddService;
      default:
        break;
    } */
  };

  const onChangeDosage = unitID => {
    let unitName;
    const dosageUnitDetails = prescriptionSettings.dosageUnits.filter(item => item.key === unitID);
    if (dosageUnitDetails && dosageUnitDetails[0]) {
      const { dosageUnitTranslators } = dosageUnitDetails[0];
      if (dosageUnitTranslators && dosageUnitTranslators.length) {
        const localizedUnitObj = dosageUnitTranslators.filter(model => model.lang === language);
        if (localizedUnitObj && localizedUnitObj[0]) unitName = localizedUnitObj[0].name;
      }
    }
    setDosageUnitId(unitID);
    setDosageName(unitName);
  };

  const onChangeDuration = unitID => {
    let unitName;
    const durationUnitDetails = prescriptionSettings.durationUnits.filter(
      item => item.key === unitID,
    );
    if (durationUnitDetails && durationUnitDetails[0]) {
      const { durationUnitTranslators } = durationUnitDetails[0];
      if (durationUnitTranslators && durationUnitTranslators.length) {
        const localizedUnitObj = durationUnitTranslators.filter(model => model.lang === language);
        if (localizedUnitObj && localizedUnitObj[0]) unitName = localizedUnitObj[0].name;
      }
    }
    setDurationUnitId(unitID);
    setDurationName(unitName);
  };

  const renderDosageSection = () => {
    if (prescriptionSettings && prescriptionSettings.dosageUnits) {
      return (
        <SectionContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FieldTitle>{localization.dosage}</FieldTitle>
            {!isValidDosageForm && <RequiredLabel>{`(${localization.required})`}</RequiredLabel>}
          </div>
          <div
            style={{
              // marginBottom: '7px',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Counter
              language={language}
              value={dosageCount}
              onChange={setDosageCount}
              extendCounterStyle={ExtendCounterStyle}
            />
            <div style={{ width: '66%' }}>
              <Field
                extendDropDownStyle={ExtendDropDownStyle}
                placeHolder={localization.dosageType}
                fieldValue={dosageUnitId}
                componentName="dosage-type"
                options={getDosageListForDropDown(prescriptionSettings.dosageUnits, language)}
                onChanges={onChangeDosage}
                icon="dropdown"
                iconSize={20}
                isDynamic
                isDropDown
                isRequired
                // isDisabled={!appointmentInfo.doctor}
                language={language}
                isValid
                fontSize={14}
              />
            </div>
          </div>
        </SectionContainer>
      );
    }
    return '';
  };

  const renderFrequencySection = () => {
    if (
      prescriptionSettings &&
      prescriptionSettings.frequencies &&
      prescriptionSettings.frequencies.length
    ) {
      return (
        <SectionContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FieldTitle>{localization.frequency}</FieldTitle>
            {!isValidDosageForm && <RequiredLabel>{`(${localization.required})`}</RequiredLabel>}
          </div>
          <OptionsContainer>
            {prescriptionSettings.frequencies.map((item, idx) => {
              let itemName;
              if (!showMoreFrequency && idx < 5) {
                const localizedObject = item.frequencyTranslators.filter(
                  freq => freq.lang === language,
                );
                if (localizedObject && localizedObject[0]) itemName = localizedObject[0].name;
                return (
                  <OptionItemContainer
                    key={item.key}
                    language={language}
                    isSelected={frequencyIds === item.key}
                    onClick={() => {
                      setFrequencyIds(item.key);
                      setFrequencyName(itemName);
                    }}
                  >
                    <OptionItemWrapper>
                      <OptionItem>{itemName}</OptionItem>
                    </OptionItemWrapper>
                  </OptionItemContainer>
                );
              } else if (showMoreFrequency) {
                const localizedObject = item.frequencyTranslators.filter(
                  freq => freq.lang === language,
                );
                if (localizedObject && localizedObject[0]) itemName = localizedObject[0].name;
                return (
                  <OptionItemContainer
                    key={item.key}
                    language={language}
                    isSelected={frequencyIds === item.key}
                    onClick={() => {
                      setFrequencyIds(item.key);
                      setFrequencyName(itemName);
                    }}
                  >
                    <OptionItemWrapper>
                      <OptionItem>{itemName}</OptionItem>
                    </OptionItemWrapper>
                  </OptionItemContainer>
                );
              }
              return '';
            })}
            {prescriptionSettings.frequencies.length > 5 && (
              <SeeMoreContainer
                language={language}
                onClick={() => toggleShowMoreFrequency(!showMoreFrequency)}
              >
                <SeeMoreWrapper>
                  <SeeMoreItem>
                    {showMoreFrequency ? localization.seeLess : localization.seeMore}
                  </SeeMoreItem>
                </SeeMoreWrapper>
              </SeeMoreContainer>
            )}
          </OptionsContainer>
        </SectionContainer>
      );
    }
    return '';
  };

  const renderTimeOfDaySection = () => {
    if (
      prescriptionSettings &&
      prescriptionSettings.timeOfDayUnits &&
      prescriptionSettings.timeOfDayUnits.length
    ) {
      return (
        <SectionContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FieldTitle>{localization.timeOfDay}</FieldTitle>
            <OptionalLabel>{`(${localization.optional})`}</OptionalLabel>
          </div>
          <OptionsContainer>
            {prescriptionSettings.timeOfDayUnits.map((item, idx) => {
              let itemName;
              if (!showMoreTimeOfDay && idx < 5) {
                const localizedObject = item.timeOfDayUnitTranslators.filter(
                  time => time.lang === language,
                );
                if (localizedObject && localizedObject[0]) itemName = localizedObject[0].name;
                return (
                  <OptionItemContainer
                    key={item.key}
                    language={language}
                    isSelected={timeOfDayIds.includes(item.key)}
                    onClick={() => {
                      if (!timeOfDayIds.includes(item.key)) {
                        setTimeOfDayIds([...timeOfDayIds, item.key]);
                        setTimeOfDayName([...timeOfDayName, itemName]);
                      } else {
                        const filteredTimeOfDaysIds = timeOfDayIds.filter(id => id !== item.key);
                        const filteredTimeOfDaysNames = timeOfDayName.filter(
                          name => name !== itemName,
                        );
                        setTimeOfDayIds(filteredTimeOfDaysIds);
                        setTimeOfDayName(filteredTimeOfDaysNames);
                      }
                    }}
                  >
                    <OptionItemWrapper>
                      <OptionItem>{itemName}</OptionItem>
                    </OptionItemWrapper>
                  </OptionItemContainer>
                );
              } else if (showMoreTimeOfDay) {
                const localizedObject = item.timeOfDayUnitTranslators.filter(
                  time => time.lang === language,
                );
                if (localizedObject && localizedObject[0]) itemName = localizedObject[0].name;
                return (
                  <OptionItemContainer
                    key={item.key}
                    language={language}
                    isSelected={item.key === timeOfDayIds}
                    onClick={() => {
                      setTimeOfDayIds(item.key);
                      setTimeOfDayName(itemName);
                    }}
                  >
                    <OptionItemWrapper>
                      <OptionItem>{itemName}</OptionItem>
                    </OptionItemWrapper>
                  </OptionItemContainer>
                );
              }
              return '';
            })}
            {prescriptionSettings.timeOfDayUnits.length > 5 && (
              <SeeMoreContainer
                language={language}
                onClick={() => toggleShowMoreTimeOfDay(!showMoreTimeOfDay)}
              >
                <SeeMoreWrapper>
                  <SeeMoreItem>
                    {showMoreTimeOfDay ? localization.seeLess : localization.seeMore}
                  </SeeMoreItem>
                </SeeMoreWrapper>
              </SeeMoreContainer>
            )}
          </OptionsContainer>
        </SectionContainer>
      );
    }
    return '';
  };

  const renderInstructionsSection = () => {
    if (
      prescriptionSettings &&
      prescriptionSettings.instructions &&
      prescriptionSettings.instructions.length
    ) {
      return (
        <SectionContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FieldTitle>{localization.instructions}</FieldTitle>
            <OptionalLabel>{`(${localization.optional})`}</OptionalLabel>
          </div>
          <OptionsContainer>
            {prescriptionSettings.instructions.map((item, idx) => {
              let itemName;
              if (!showMoreInstructions && idx < 5) {
                const localizedObject = item.instructionTranslators.filter(
                  inst => inst.lang === language,
                );
                if (localizedObject && localizedObject[0]) itemName = localizedObject[0].name;
                return (
                  <OptionItemContainer
                    key={item.key}
                    language={language}
                    isSelected={item.key === instructionId}
                    onClick={() => {
                      setInstructionId(item.key);
                      setInstructionName(itemName);
                    }}
                  >
                    <OptionItemWrapper>
                      <OptionItem>{itemName}</OptionItem>
                    </OptionItemWrapper>
                  </OptionItemContainer>
                );
              } else if (showMoreInstructions) {
                const localizedObject = item.instructionTranslators.filter(
                  inst => inst.lang === language,
                );
                if (localizedObject && localizedObject[0]) itemName = localizedObject[0].name;
                return (
                  <OptionItemContainer
                    key={item.key}
                    language={language}
                    isSelected={item.key === instructionId}
                    onClick={() => {
                      setInstructionId(item.key);
                      setInstructionName(itemName);
                    }}
                  >
                    <OptionItemWrapper>
                      <OptionItem>{itemName}</OptionItem>
                    </OptionItemWrapper>
                  </OptionItemContainer>
                );
              }
              return '';
            })}
            {prescriptionSettings.instructions.length > 5 && (
              <SeeMoreContainer
                language={language}
                onClick={() => toggleShowMoreInstructions(!showMoreInstructions)}
              >
                <SeeMoreWrapper>
                  <SeeMoreItem>
                    {showMoreInstructions ? localization.seeLess : localization.seeMore}
                  </SeeMoreItem>
                </SeeMoreWrapper>
              </SeeMoreContainer>
            )}
          </OptionsContainer>
        </SectionContainer>
      );
    }
    return '';
  };

  const renderDurationSection = () => {
    if (prescriptionSettings && prescriptionSettings.durationUnits) {
      return (
        <SectionContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FieldTitle>{localization.duration}</FieldTitle>
            {!isValidDosageForm && <RequiredLabel>{`(${localization.required})`}</RequiredLabel>}
          </div>
          <div
            style={{
              // marginBottom: '7px',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Counter
              language={language}
              value={durationCount}
              onChange={setDurationCount}
              extendCounterStyle={ExtendCounterStyle}
            />
            <div style={{ width: '66%' }}>
              <Field
                extendDropDownStyle={ExtendDropDownStyle}
                placeHolder={localization.durationType}
                fieldValue={durationUnitId}
                componentName="durations-type"
                options={getDurationsListForDropDown(prescriptionSettings.durationUnits, language)}
                onChanges={onChangeDuration}
                icon="dropdown"
                iconSize={20}
                isDynamic
                isDropDown
                isRequired
                // isDisabled={!appointmentInfo.doctor}
                language={language}
                isValid
                fontSize={14}
              />
            </div>
          </div>
        </SectionContainer>
      );
    }
    return '';
  };

  const validateDosageForm = () => {
    let isValid = true;
    if (
      dosageCount === 0 ||
      !dosageUnitId ||
      durationCount === 0 ||
      !durationName ||
      !frequencyIds
    ) {
      isValid = false;
      setDosageFormValidation(isValid);
    }
    return isValid;
  };

  const submitMedication = () => {
    if (validateDosageForm()) {
      onCloseAction(false);
      props.addMedicationToPrescriptionList({
        ...selectedMedicationItemDetails,
        PrescriptionItemTypeId: prescriptionItemTypesEnum.medicine,
        dosageCount,
        dosageUnitId,
        frequencyIds,
        timeOfDayIds,
        instructionId,
        durationCount,
        durationUnitId,
        additionalNote,
        dosageName,
        frequencyName,
        timeOfDayName,
        instructionName,
        durationName,
      });
      resetModalContentAfterSubmitting();
    }
  };

  const stepSelection = () => {
    if (stepIndex === SETTINGS.PREV_DOSAGE_SETTINGS) {
      return (
        <InnerContainer>
          <SectionContainer>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FieldTitle>{localization.previouslyAddedDosages}</FieldTitle>
            </div>
            <div
              style={{
                marginTop: '7px',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              {selectedMedicationItemDetails &&
                selectedMedicationItemDetails.settings &&
                selectedMedicationItemDetails.settings.map((setting, idx) => {
                  const {
                    dosageQuantity,
                    dosageUnitName,
                    frequencyName: freqNameString,
                    duration,
                    durationUnitName,
                    //
                    timesOfDay,
                    instructionName: instructionNameString,
                  } = setting;

                  // line 1 ex: 1 pill once a day, for 7 days
                  const line1 = `${dosageQuantity ? `${dosageQuantity} ${dosageUnitName}` : ''} ${
                    freqNameString ? `, ${freqNameString}` : ''
                  } ${duration ? `, ${duration} ${durationUnitName}` : ''}`;
                  // line 2 es: in the morning after meals
                  let timeOfDayString = '';
                  if (timesOfDay && timesOfDay.length) {
                    timesOfDay.forEach((timeOfDayNameItem, index) => {
                      if (index === 0) {
                        timeOfDayString = timeOfDayNameItem.name;
                      } else if (timeOfDayName.length === index + 1) {
                        timeOfDayString = `${timeOfDayString}, ${timeOfDayNameItem.name},`;
                      } else {
                        timeOfDayString = `, ${timeOfDayString}, ${timeOfDayNameItem.name}`;
                      }
                    });
                  }

                  const timeOfDayIdsList = [];
                  const timeOfDayNames = [];
                  if (setting.timesOfDay[0] && setting.timesOfDay && setting.timesOfDay.length) {
                    setting.timesOfDay.forEach(timeOfDay => {
                      timeOfDayNames.push(timeOfDay.name);
                      timeOfDayIdsList.push(timeOfDay.id);
                    });
                  }

                  const line2 = `${timeOfDayString || ''} ${
                    timeOfDayString && instructionNameString ? ',' : ''
                  } ${instructionNameString ? `${instructionNameString}` : ''}`;
                  const onselectSetting = () => {
                    props.setSelectedMedicationItem({
                      ...selectedMedicationItemDetails,
                      dosageCount: setting.dosageQuantity,
                      dosageUnitId: setting.dosageUnitId,
                      durationCount: setting.duration,
                      durationUnitId: setting.durationUnitId,
                      instructionId: setting.instructionId,
                      timeOfDayIds: timeOfDayIdsList,
                      frequencyIds: setting.frequencyId,
                      additionalNote: setting.notes,
                      dosageName: setting.dosageUnitName,
                      frequencyName: setting.frequencyName,
                      timeOfDayName: timeOfDayNames,
                      instructionName: setting.instructionName,
                      durationName: setting.durationUnitName,
                    });
                    setSelectedSetting(idx);
                  };

                  return (
                    <SettingContainer
                      isSelected={selectedSetting === idx}
                      onClick={() => onselectSetting()}
                    >
                      <SettingWrapper>
                        <SettingSelectionContainer>
                          <SettingSelectionWrapper>
                            <CheckCircle isSelected={selectedSetting === idx}>
                              {selectedSetting === idx && (
                                <Icon
                                  icon={new IconsStore(Icons).getIcon('success')}
                                  width={12}
                                  color="#ffff"
                                />
                              )}
                            </CheckCircle>
                          </SettingSelectionWrapper>
                        </SettingSelectionContainer>
                        <SettingDescripContainer>
                          <SettingDescripWrapper>
                            <SettingDescripMain>{line1}</SettingDescripMain>
                            <SettingDescripSub>{line2}</SettingDescripSub>
                          </SettingDescripWrapper>
                        </SettingDescripContainer>
                      </SettingWrapper>
                    </SettingContainer>
                  );
                })}
              <NewButton
                btnText={localization.addNewDosage}
                onClick={() => {
                  setStepIndex(SETTINGS.SET_DOSAGE_SETTINGS);
                }}
                iconName="plus"
                extendButtonStyle={extendExportButton}
                iconColor="#484848"
              />
            </div>
          </SectionContainer>
        </InnerContainer>
      );
    } else if (stepIndex === SETTINGS.SET_DOSAGE_SETTINGS) {
      return (
        <InnerContainer>
          {renderDosageSection()}
          {renderFrequencySection()}
          {renderTimeOfDaySection()}
          {renderInstructionsSection()}
          {renderDurationSection()}
          <SectionContainer>
            <div>
              <Paragraph
                extendDropDownStyle={ExtendInputFieldsStyle}
                fieldValue={additionalNote}
                componentName="additional-instructions"
                isDynamic
                onChange={setAdditionalNote}
                // validation={}
                isValid
                placeHolder={localization.additionalInst}
              />
            </div>
          </SectionContainer>
        </InnerContainer>
      );
    }
    return '';
  };

  const renderModalView = () => {
    if (prescriptionSettingsLoadState === status.FETCHING) {
      return (
        <StatusContainer>
          <StatusContainerWrapper>
            <Spinner radius={100} />
          </StatusContainerWrapper>
        </StatusContainer>
      );
    } else if (prescriptionSettingsLoadState === status.SUCCESS) {
      const isBackBtnRendered =
        stepIndex === SETTINGS.SET_DOSAGE_SETTINGS &&
        selectedMedicationItemDetails.settings &&
        selectedMedicationItemDetails.settings.length;
      return (
        <div style={{ width: '100%', height: '86%' }}>
          <Container>
            <ProductContainer>
              <ProductWrapper>
                <ProductImg
                  src={
                    selectedMedicationItemDetails &&
                    selectedMedicationItemDetails.productShapeIconUrl
                  }
                  alt="productImg"
                />
                <ProductDetailsContainer>
                  <ProductDetailsWrapper>
                    <ProductDetailsName>
                      {selectedMedicationItemDetails && selectedMedicationItemDetails.productName}
                    </ProductDetailsName>
                    <ProductDetailsDescription>
                      {selectedMedicationItemDetails &&
                        selectedMedicationItemDetails.activeIngrediant}
                    </ProductDetailsDescription>
                  </ProductDetailsWrapper>
                </ProductDetailsContainer>
              </ProductWrapper>
            </ProductContainer>
            {stepSelection()}
          </Container>
          <ButtonContainer>
            <div>
              <NotificationBar
                isShowed={isNotificationBarShown()}
                iconName="exclamation"
                iconColor="#fff"
                iconSize={20}
                notificationMessage={getNotificationBarmessage()}
              />
            </div>
            <div style={{ display: 'flex' }}>
              {isBackBtnRendered && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0px 12px' }}>
                  <BackButton
                    onClick={() => {
                      setStepIndex(SETTINGS.PREV_DOSAGE_SETTINGS);
                    }}
                  >
                    <p>{localization.back}</p>
                  </BackButton>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SaveButton
                  onClick={() => {
                    submitMedication();
                  }}
                >
                  <p>{localization.addToPrescription}</p>
                </SaveButton>
              </div>
            </div>
          </ButtonContainer>
        </div>
      );
    } else if (prescriptionSettingsLoadState === status.FAIL) {
      return (
        <StatusContainer>
          <StatusContainerWrapper>
            <ErrorImage src={ErrorImg} alt="" />
            <ErrorMessageContainer>
              <ErrorMessage>{localization.somethingWentWrong}</ErrorMessage>
            </ErrorMessageContainer>
          </StatusContainerWrapper>
        </StatusContainer>
      );
    }
    return '';
  };

  return (
    <div>
      <ModalContainer isModalShowed={showDosageSelectionModal}>
        <InnerPopupContainer language={language}>
          <PopUpHeader>
            <HeaderTitle>{localization.dosageSelection}</HeaderTitle>
            <IconContainer>
              <CloseLink
                onClick={() => {
                  onCloseAction(false);
                  resetModalContentAfterSubmitting();
                }}
              >
                <CloseIcon icon={new IconsStore(Icons).getIcon('close')} width={12} color="#ffff" />
              </CloseLink>
            </IconContainer>
          </PopUpHeader>
          {renderModalView()}
        </InnerPopupContainer>
      </ModalContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedMedicationItemDetails: state.pharma.selectedMedicationItemDetails,
  prescriptionSettings: state.pharma.prescriptionSettings,
  prescriptionSettingsLoadState: state.pharma.prescriptionSettingsLoadState,
});

DosageSelectionModal.propTypes = {
  onCloseAction: PropTypes.func,
  language: PropTypes.string,
  localization: PropTypes.object,
  showDosageSelectionModal: PropTypes.bool,
  selectedMedicationItemDetails: PropTypes.object,
  getPrescriptionSettings: PropTypes.func,
  addMedicationToPrescriptionList: PropTypes.func,
  prescriptionSettings: PropTypes.object,
  prescriptionSettingsLoadState: PropTypes.string,
  setSelectedMedicationItem: PropTypes.func,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getPrescriptionSettings, addMedicationToPrescriptionList, setSelectedMedicationItem },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DosageSelectionModal);
