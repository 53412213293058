import React from 'react';
import PropTypes from 'prop-types';
import { NewButton } from '@vezeeta/web-components';
import {
  ModalContainer,
  InnerPopupContainer,
  Container,
  Message,
  MessageContainer,
  MessageWarning,
  Wrapper,
  ButtonsContainer,
  ButtonsWrapper,
  extendCancelButtonStyle,
  extendDiscardButtonStyle,
} from './DiscardModal.styles';

const DiscardModal = ({ onCloseAction, language, localization, onSubmit, showModal }) => (
  <div>
    <ModalContainer isModalShowed={showModal}>
      <InnerPopupContainer language={language}>
        <Container>
          <Wrapper>
            <MessageContainer>
              <Message>{localization.discardUnsavedChanges}</Message>
              <MessageWarning>{localization.discardWarning}</MessageWarning>
            </MessageContainer>
            <ButtonsContainer>
              <ButtonsWrapper>
                <NewButton
                  btnText={localization.cancel}
                  onClick={() => onCloseAction()}
                  extendButtonStyle={extendCancelButtonStyle}
                />
                <NewButton
                  btnText={localization.discard}
                  onClick={() => onSubmit()}
                  extendButtonStyle={extendDiscardButtonStyle}
                />
              </ButtonsWrapper>
            </ButtonsContainer>
          </Wrapper>
        </Container>
      </InnerPopupContainer>
    </ModalContainer>
  </div>
);

DiscardModal.propTypes = {
  onCloseAction: PropTypes.func,
  language: PropTypes.string,
  localization: PropTypes.object,
  onSubmit: PropTypes.func,
  showModal: PropTypes.bool,
};

export default DiscardModal;
