import React from 'react';
import PropTypes from 'prop-types';
import Icons from '@vezeeta/web-icons';
import { Icon, IconsStore } from '@vezeeta/web-components';
import { toIndiaDigits } from '@vezeeta/enterprise-utils/lib/Helpers';

import {
  MedicationDetails,
  MedicationDosage,
  MedicationImage,
  MedicationImageConatiner,
  MedicationItemContainer,
  MedicationItemWrapper,
  MedicationTitle,
  AddItemIconContainer,
  AddItemIconWrapper,
} from './MedicationPrevItem.style';

const MedicationItem = ({
  language,
  item,
  appendMedicationItemAction,
  presciptionMedicationsList,
}) => {
  const {
    dosageCount,
    durationCount,
    productName,
    productShapeIconUrl,
    productKey,
    dosageName,
    durationName,
    frequencyName,
    instructionName,
    timeOfDayName,
    mainImageUrl,
  } = item;

  const isAdded = presciptionMedicationsList.find(med => med.productKey === productKey);

  let timeOfDayString = '';
  if (timeOfDayName && timeOfDayName.length) {
    timeOfDayName.forEach((timeOfDayNameItem, idx) => {
      if (idx === 0) {
        timeOfDayString = timeOfDayNameItem;
      } else if (timeOfDayName.length === idx + 1) {
        timeOfDayString = `${timeOfDayString}, ${timeOfDayNameItem},`;
      } else {
        timeOfDayString = `, ${timeOfDayString}, ${timeOfDayNameItem}`;
      }
    });
  }

  const instructionNameString = instructionName ? `${instructionName},` : '';

  return (
    <MedicationItemContainer key={productKey}>
      <MedicationItemWrapper>
        <MedicationImageConatiner>
          <MedicationImage src={mainImageUrl || productShapeIconUrl} alt="" />
        </MedicationImageConatiner>
        <MedicationDetails>
          <MedicationTitle>{productName}</MedicationTitle>
          <MedicationDosage>{`${
            language === 'en' ? dosageCount : toIndiaDigits(String(dosageCount))
          } ${dosageName}, ${frequencyName}, ${timeOfDayString} ${instructionNameString} ${
            language === 'en' ? durationCount : toIndiaDigits(String(durationCount))
          } ${durationName}`}</MedicationDosage>
        </MedicationDetails>
        <AddItemIconContainer
          isAdded={isAdded}
          onClick={() => {
            if (appendMedicationItemAction && !isAdded) appendMedicationItemAction();
          }}
        >
          <AddItemIconWrapper>
            <Icon
              className="icon"
              icon={new IconsStore(Icons).getIcon(isAdded ? 'success' : 'plus')}
              width={14}
              color={isAdded ? '#ffff' : '#0070CD'}
            />
          </AddItemIconWrapper>
        </AddItemIconContainer>
      </MedicationItemWrapper>
    </MedicationItemContainer>
  );
};

MedicationItem.propTypes = {
  language: PropTypes.string,
  item: PropTypes.object,
  presciptionMedicationsList: PropTypes.array,
  appendMedicationItemAction: PropTypes.func,
};

export default MedicationItem;
