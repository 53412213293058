import { toIndiaDigits } from '@vezeeta/enterprise-utils/lib/Helpers';

export const getPhoneNumber = mobilePhone => {
  let paientPhone;
  if (mobilePhone && String(mobilePhone).charAt(0) === '0') {
    paientPhone = String(mobilePhone).slice(1);
  } else {
    paientPhone = mobilePhone;
  }
  return paientPhone;
};

export const getPatientAge = (birthDate, language) => {
  let patientAge;
  if (birthDate) {
    patientAge = Math.floor((new Date() - new Date(birthDate)) / (365 * 24 * 60 * 60 * 1000));
    if (language !== 'en') patientAge = toIndiaDigits(String(patientAge));
  }
  return patientAge || '--';
};

export const getLocalizedNumbers = (value, language) => {
  if (language === 'en') return value;
  return toIndiaDigits(String(value));
};
