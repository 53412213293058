export const MIXPANEL_EVENTS = {
  ADDERX_PATIENTPROFILE: 'Add_eRx',
  CREATEERX_PATIENTPROFILE: 'Create_eRx',
  UPLOAD_PRESCRIPTION: 'Upload_Prescription',
  SEARCH_MEDICATION: 'Search_Medication',
  ADD_MEDICATION: 'Add_Medication',
  SEND_PRESCRIPTION: 'Send_eRx',
  VIEWERX_PATIENTPROFILE: 'View_eRx',
  VIEWEPRESCRIPTION_PATIENTPROFILE: 'Viewe_Prescription',
  SEARCH_LAB_TEST: 'Search_Lab_Test',
  ADD_LAB_TEST: 'Add_Lab_Test',
};
