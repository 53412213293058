import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconsStore } from '@vezeeta/web-components';
import { toIndiaDigits } from '@vezeeta/enterprise-utils/lib/Helpers';
import Icons from '@vezeeta/web-icons';

import {
  ButtonConatainer,
  ButtonConatainerWrapper,
  Container,
  ValueContainer,
  ValueContainerWrapper,
  ValueText,
} from './Counter.style';

const Counter = ({ language, value, onChange, extendCounterStyle }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    <Container extendCounterStyle={extendCounterStyle}>
      <ButtonConatainer
        onClick={() => {
          if (value && value !== 0) onChange(value - 1);
        }}
      >
        <ButtonConatainerWrapper>
          <Icon icon={new IconsStore(Icons).getIcon('minus')} width={16} color="#484848" />
        </ButtonConatainerWrapper>
      </ButtonConatainer>
      <ValueContainer>
        <ValueContainerWrapper>
          <ValueText>{language === 'en' ? value : toIndiaDigits(String(value))}</ValueText>
        </ValueContainerWrapper>
      </ValueContainer>
      <ButtonConatainer
        onClick={() => {
          onChange(value + 1);
        }}
      >
        <ButtonConatainerWrapper>
          <Icon icon={new IconsStore(Icons).getIcon('plus')} width={8} color="#484848" />
        </ButtonConatainerWrapper>
      </ButtonConatainer>
    </Container>
  </div>
);

Counter.propTypes = {
  language: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  extendCounterStyle: PropTypes.object,
};

export default Counter;
