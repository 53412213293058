import styled from 'styled-components';

export const MedicationsList = styled.div`
  // max-height: 187px;
  // overflow: auto;
  /* ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9c9c9c;
    border-radius: 15px;
  } */
`;

export const MedicationItemContainer = styled.div`
  padding: 13px 0px;
  border-bottom: ${props => (props.isLastItem ? 'unset' : '1px solid #eef0f2')};
`;

export const MedicationItemWrapper = styled.div`
  display: flex;
`;

export const MedicationImageConatiner = styled.div``;

export const MedicationImage = styled.img`
  width: 32px;
  height: 32px;
`;

export const MedicationDetails = styled.div`
  padding: 0px 10px;
  width: 100%;
`;

export const MedicationTitle = styled.p`
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  color: #484848;
`;

export const MedicationDosage = styled.p`
  font-size: 11px;
  line-height: 18px;
  color: #9c9c9c;
`;

export const ItemOptionsContainer = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
`;
export const IconContainer = styled.div`
  display: flex;
  margin: 0px 4px;
  padding: 4px 0px;
  width: 28px;
  height: 28px;
  background: #f1f4f6;
  border-radius: 7px;
  flex-direction: row;
  justify-content: center;
`;

export const OptionsListContainer = styled.div`
  width: fit-content;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.16));
  border-radius: 7px;
  width: 160px;
  height: 74px;
  // border: 1px solid;
  margin: 5px 0px;
  position: absolute;
  background: #fff;
  top: 0px;
  z-index: 5;
  right: ${props => (props.language === 'en' ? '40px' : 'unset')};
  left: ${props => (props.language === 'ar' ? '40px' : 'unset')};
`;

export const OptionsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionsListItem = styled.div`
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  color: #484848;
  padding: 10px 13px;
  cursor: pointer;
  box-shadow: inset 0px -1px 0px #f1f4f6;
`;

export const OptionalLabel = styled.p`
  color: #9c9c9c;
  font-weight: normal;
  font-size: 11px;
  line-height: 19px;
  margin: 0px 4px;
`;

export const AddItemIconContainer = styled.div`
  background: #e6f1ff;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AddItemIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const AddedMedicationsContainer = styled.div`
  margin-top: 12px;
`;

export const AddedMedicationsTitle = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #666666;
`;

export const StatusContainer = styled.div`
  padding: 16px 0px 0px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const StatusContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ShowAllLinkContainer = styled.div``;
export const ShowAllLinkWrapper = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  color: #0070cd;
  cursor: pointer;
  display: inline-block;
`;
