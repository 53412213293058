import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icons from '@vezeeta/web-icons';
import { Icon, IconsStore } from '@vezeeta/web-components';
import LabTestImg from 'app/assets/images/labTest.png';

import {
  LabTestDetails,
  LabsImage,
  LabsImageConatiner,
  LabTestItemContainer,
  LabTestItemWrapper,
  LabTestTitle,
  IconContainer,
  ItemOptionsContainer,
  OptionsListContainer,
  OptionsListItem,
  OptionsListWrapper,
} from './LabTestItem.style';

const LabTestItem = ({
  index,
  item,
  removeLabTestsFromPrescriptionList,
  listLength,
  display,
  language,
  localization,
}) => {
  const { productName, productKey /* , description */ } = item;
  const [isOptionsListOpened, toggleOptionsList] = useState(false);

  const optionListListenerHandler = event => {
    const myElementToCheckIfClicksAreInsideOf = document.querySelector(`#Labitem${index}`);
    if (!myElementToCheckIfClicksAreInsideOf.contains(event.target)) {
      toggleOptionsList(false);
    }
  };

  const addListener = () => {
    document.body.addEventListener('click', optionListListenerHandler, true);
  };

  const removeListener = () => {
    document.body.removeEventListener('click', optionListListenerHandler, true);
  };

  useEffect(() => {
    if (!display) addListener();
    return () => {
      if (!display) removeListener();
    };
  }, []);

  const removeItem = labkey => {
    toggleOptionsList(false);
    removeListener();
    removeLabTestsFromPrescriptionList(labkey);
  };

  return (
    <LabTestItemContainer key={productKey} isLastItem={listLength - 1 === index}>
      <LabTestItemWrapper>
        <LabsImageConatiner>
          <LabsImage src={LabTestImg} alt="" />
        </LabsImageConatiner>
        <LabTestDetails>
          <LabTestTitle>{productName}</LabTestTitle>
          {/* <LabTestDescription>{description}</LabTestDescription> */}
        </LabTestDetails>
        {!display && (
          <ItemOptionsContainer id={`Labitem${index}`}>
            <IconContainer
              onClick={() => {
                toggleOptionsList(!isOptionsListOpened);
              }}
            >
              <Icon
                className="icon"
                icon={new IconsStore(Icons).getIcon('horizDots')}
                width={14}
                color="#484848"
              />
            </IconContainer>
            {isOptionsListOpened && (
              <OptionsListContainer language={language}>
                <OptionsListWrapper>
                  <OptionsListItem onClick={() => removeItem(productKey)}>
                    <p>{localization.remove}</p>
                  </OptionsListItem>
                </OptionsListWrapper>
              </OptionsListContainer>
            )}
          </ItemOptionsContainer>
        )}
      </LabTestItemWrapper>
    </LabTestItemContainer>
  );
};

LabTestItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  removeLabTestsFromPrescriptionList: PropTypes.func,
  listLength: PropTypes.array,
  language: PropTypes.string,
  localization: PropTypes.object,
  display: PropTypes.bool,
};

export default LabTestItem;
