import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconsStore } from '@vezeeta/web-components';
import Icons from '@vezeeta/web-icons';
import {
  AdditionalInfoContainer,
  Container,
  MainInfoContainer,
  PatientInfoContainer,
  Wrapper,
  BigBold,
  GrayBold,
  NormalSmall,
  MarginedIcon,
  InfoContainer,
  RowContainer,
} from './PatientInfo.style';
import { getLocalizedNumbers, getPatientAge, getPhoneNumber } from './PatientInfo.helper';

const PatientInfo = ({ language, patientProfileInfo, localization }) => {
  const { FullName, Identifier, CountryCode, MobilePhone, EmailAddress, BirthDate, insuranceName } =
    patientProfileInfo || {};
  const paientPhone = getPhoneNumber(MobilePhone);
  const patientAge = getPatientAge(BirthDate, language);

  return (
    <Container>
      <Wrapper>
        <InfoContainer>
          <PatientInfoContainer>
            <MainInfoContainer>
              <RowContainer style={{ justifyContent: 'space-between' }}>
                <BigBold>{FullName}</BigBold>
              </RowContainer>

              <RowContainer>
                <GrayBold>{`${localization.patientNumber} ${Identifier ||
                  localization.na}`}</GrayBold>
                <MarginedIcon
                  className="icon"
                  icon={new IconsStore(Icons).getIcon('circle')}
                  width={6}
                  color="#9C9C9C"
                />
                <NormalSmall
                  style={{ margin: '0px' }}
                >{`${localization.age}: ${patientAge} `}</NormalSmall>
              </RowContainer>

              <AdditionalInfoContainer>
                <RowContainer>
                  <Icon
                    className="icon"
                    icon={new IconsStore(Icons).getIcon('phoneFilled')}
                    width={16}
                    color="#0070CD"
                  />
                  <NormalSmall>
                    {getLocalizedNumbers(`${CountryCode}${paientPhone}`, language)}
                  </NormalSmall>
                </RowContainer>
                <RowContainer>
                  <Icon
                    className="icon"
                    icon={new IconsStore(Icons).getIcon('envelope2')}
                    width={16}
                    color="#0070CD"
                  />
                  <NormalSmall>{EmailAddress || localization.na}</NormalSmall>
                </RowContainer>
                <RowContainer>
                  <Icon
                    className="icon"
                    icon={new IconsStore(Icons).getIcon('script')}
                    width={16}
                    color="#0070CD"
                  />
                  <GrayBold style={{ margin: '0px 8px' }}>{localization.insurance}</GrayBold>
                  <NormalSmall
                    style={{
                      margin: '0px',
                      width: '70%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textAlign: language === 'en' ? 'left' : 'right',
                      direction: language === 'en' ? 'ltr' : 'rtl',
                    }}
                  >
                    {insuranceName || localization.na}
                  </NormalSmall>
                </RowContainer>
              </AdditionalInfoContainer>
            </MainInfoContainer>
          </PatientInfoContainer>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};

PatientInfo.propTypes = {
  language: PropTypes.string,
  patientProfileInfo: PropTypes.object,
  localization: PropTypes.object,
};

export default PatientInfo;
