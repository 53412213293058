import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  opacity: ${props => (props.isModalShowed ? '1' : '0')};
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: ${props => (props.isModalShowed ? '0' : '15px')};
  transition-delay: ${props => (props.isModalShowed ? '0s, 75ms, 75ms' : '0s, 0s, 0s')};
  transition-duration: 125ms, 50ms, 50ms;
  transition-property: top, opacity, z-index;
  transition-timing-function: linear;
  z-index: ${props => (props.isModalShowed ? '1000' : '-1')};
  padding: 0px 20px;
  ${props => props.theme.media.desktop`
    padding: 0px;
  `};
`;

export const InnerPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  border-radius: 5px;
  position: relative;
  width: 390px;
  height: 188px;
  left: ${props => (props.language === 'en' ? 'calc(50% - 195px)' : 'unset')};
  right: ${props => (props.language === 'ar' ? 'calc(50% - 195px)' : 'unset')};
  top: 15px;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
  background-color: #fff;
`;

export const Container = styled.div`
  padding: 32px 0px;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
`;

export const Message = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #484848;
  text-align: center;
  margin-bottom: 8px;
`;

export const MessageWarning = styled.p`
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #666666;
  text-align: center;
`;

export const ButtonsContainer = styled.div``;
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const extendCancelButtonStyle = css`
  width: 156px;
  color: #484848;
  background: #f1f4f6;
`;

export const extendDiscardButtonStyle = css`
  ${extendCancelButtonStyle};
  color: #ffffff;
  background: #0070cd;
`;
