import styled from 'styled-components';
import { Icon } from '@vezeeta/web-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  height: fit-content;
  width: 25%;
  border-radius: 7px;
  position: sticky;
  top: 0%;
  ${props => props.theme.media.tablet`
    width: 100%;
    margin-bottom: 8px;
    position: unset;
  `};
  ${props => props.theme.media.phone`
    width: 100%;
    margin-bottom: 8px;
    position: unset;
  `};
`;
export const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: space-between;
  min-height: 140px;
  width: 100%;
`;
export const PatientInfoContainer = styled.div``;
export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AdditionalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 13px;
`;
export const TabsContainer = styled.div`
  display: flex;
`;

export const BigBold = styled.p`
  color: #0070cd;
  font-weight: bold;
  font-size: 16px;
`;
export const GrayBold = styled.p`
  color: #9c9c9c;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
`;
export const NormalSmall = styled.p`
  font-size: 13px;
  color: #484848;
  line-height: 24px;
  direction: ltr;
  margin-inline: 7px;
`;

export const MarginedIcon = styled(Icon)`
  margin: 0px 7px;
`;

export const TabContainer = styled.div`
  margin-right: ${props => (props.isRTL ? 'unset' : '16px')};
  margin-left: ${props => (props.isRTL ? '16px' : 'unset')};
  padding: 15px 0px;
  border-bottom: ${props => (props.isSelected ? '3px solid #0070cd' : 'unset')};
  cursor: pointer;
`;
export const TabTitle = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  color: ${props => (props.isSelected ? '#0070cd' : '#9C9C9C')};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  background-color: #f1f4f6;
  color: #484848;
  border-radius: 7px;
  cursor: pointer;
  padding: 0px 16px;
  height: 32px;
`;

export const ButtonText = styled.p`
  font-size: 13px;
  line-height: 16px;
`;

export const ButtonTextContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0px 3px;
`;

export const IconConatiner = styled.div`
  display: flex;
  margin: 0px 3px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
