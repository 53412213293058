import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #e3e6ea;
  box-sizing: border-box;
  border-radius: 7px;
  width: 160px;
  justify-content: space-between;
  ${props => (props.extendCounterStyle ? props.extendCounterStyle : '')};
`;

export const ButtonConatainer = styled.div`
  width: 37px;
  height: 37px;
  background: #f1f4f6;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;
export const ButtonConatainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ValueContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const ValueText = styled.p`
  font-size: 12px;
  line-height: 19px;
  color: #484848;
`;
