export const getInusranceProviderInfo = (insuranceKey, insurancesList) => {
  const insuranceProviderObj = {
    key: undefined,
    name: {
      en: '',
      ar: '',
    },
  };
  if (insuranceKey && insurancesList && insurancesList.length) {
    insuranceProviderObj.key = insuranceKey;
    const insuranceProviderModel = insurancesList.filter(
      insuranceObj => insuranceObj.InsuranceKey === insuranceKey,
    );
    if (insuranceProviderModel && insuranceProviderModel[0]) {
      const { LanguageItemModels } = insuranceProviderModel[0];
      const enModel = LanguageItemModels.filter(model => model.LanguageId === 1);
      const arModel = LanguageItemModels.filter(model => model.LanguageId === 2);
      insuranceProviderObj.name.en = enModel && enModel[0] && enModel[0].Name;
      insuranceProviderObj.name.ar = arModel && arModel[0] && arModel[0].Name;
    }
  }
  return insuranceProviderObj;
};

export const parseQueryString = url => {
  const urlParams = {};
  url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
    urlParams[$1] = $3;
  });

  return urlParams;
};
