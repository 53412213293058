import styled, { css } from 'styled-components';
import { Icon } from '@vezeeta/web-components';

export const ModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  opacity: ${props => (props.isModalShowed ? '1' : '0')};
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: ${props => (props.isModalShowed ? '0' : '12px')};
  transition-delay: ${props => (props.isModalShowed ? '0s, 75ms, 75ms' : '0s, 0s, 0s')};
  transition-duration: 125ms, 50ms, 50ms;
  transition-property: top, opacity, z-index;
  transition-timing-function: linear;
  z-index: ${props => (props.isModalShowed ? '1000' : '-1')};
  padding: 0px 16px;
  ${props => props.theme.media.desktop`
    padding: 0px;
  `};
`;

export const InnerPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  border-radius: 4px;
  position: relative;
  width: 518px;
  max-height: 95vh;
  left: ${props => (props.language === 'en' ? 'calc(50% - 259px)' : 'unset')};
  right: ${props => (props.language === 'ar' ? 'calc(50% - 259px)' : 'unset')};
  top: 12px;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
  background-color: #fff;
`;

export const IconContainer = styled.div`
  display: inline-flex;
  padding: 0px 20px;
`;

export const HeaderTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  padding: 12px 16px;
  display: inline-flex;
  color: #fff;
`;

export const PopUpHeader = styled.div`
  // margin: 10px 0px 15px;
  background-color: #0070cd;
  border-radius: 4px 4px 0px 0px;
  color: #fff;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  box-shadow: inset 0px -1px 0px #e3e6ea;
`;

export const CloseLink = styled.div`
  color: #666666;
  font-size: 13px;
  line-height: 19px;
  padding: 10px 0px;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 24px;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  justify-content: ${props => (props.alignEnd ? 'flex-end' : 'flex-start')};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: solid 1px #e3e6ea;
  padding: 8px 0px;
  height: calc(95vh - 100px);
`;

export const SaveButton = styled.div`
  align-items: center;
  padding: 10px 19px;
  color: #fff;
  cursor: pointer;
  background-color: #0070cd;
  border-radius: 7px;
  font-size: 13px;
  font-weight: bold;
`;

export const BackButton = styled(SaveButton)`
  color: #484848;
  cursor: pointer;
  background-color: #f1f4f6;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 8px 15px;
  display: inline-flex;
  justify-content: space-between;
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
`;

export const GrayOverLay = styled.div`
  background-color: rgb(185 185 185 / 62%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const InnerContainer = styled.div`
  padding: 13px;
  overflow: auto;
  max-height: 525px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #9c9c9c;
    border-radius: 15px;
  }
`;

export const ProductContainer = styled.div`
  width: 100%;
  padding: 4px 13px;
  border-bottom: solid 1px #e3e6ea;
`;
export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ProductImg = styled.img`
  width: 51px;
  height: 51px;
`;
export const ProductDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 13px;
`;
export const ProductDetailsWrapper = styled.div``;
export const ProductDetailsName = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #484848;
`;
export const ProductDetailsDescription = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #9c9c9c;
`;

export const FieldTitle = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  color: #484848;
`;

export const OptionItemContainer = styled.div`
  width: 160px;
  background: ${props => (props.isSelected ? '#E5F1FF' : '#f1f4f6')};
  color: ${props => (props.isSelected ? '#0070CD' : '#484848')};
  border: ${props => (props.isSelected ? '1px solid #0070CD' : '1px solid #f1f4f6')};
  border-radius: 7px;
  padding: 5px 0px;
  cursor: pointer;
  margin-bottom: 7px;
  margin-right: ${props => (props.language === 'en' ? '2px' : 'unset')};
  margin-left: ${props => (props.language === 'ar' ? '2px' : 'unset')};
`;
export const OptionItemWrapper = styled.div``;
export const OptionItem = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
`;

export const SeeMoreContainer = styled(OptionItemContainer)`
  background: #fff;
  color: #484848;
  border: 1px solid #e3e6ea;
`;
export const SeeMoreWrapper = styled.div``;
export const SeeMoreItem = styled(OptionItem)``;

export const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SectionContainer = styled.div`
  margin-bottom: 7px;
`;

export const ExtendInputFieldsStyle = css`
  background-color: #fff;
  box-sizing: border-box;
  margin: 3px 0px;
  #input-container-additional-instructions {
    font-size: 14px;
  }
  #input-container-additional-instructions::placeholder {
    font-size: 14px;
  }
`;

export const ExtendDropDownStyle = css`
  background-color: #fff;
  box-sizing: border-box;
  // margin: 4px 0px;
  margin-bottom: 7px;
  height: 40px;
  font-size: 14px;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 80px 0px;
`;

export const StatusContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ErrorImage = styled.img`
  width: 128px;
  margin: 0 auto;
`;

export const ErrorMessageContainer = styled.div`
  margin-top: 11px;
`;

export const ErrorMessage = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #484848;
`;

export const OptionalLabel = styled.p`
  color: #9c9c9c;
  font-weight: normal;
  font-size: 11px;
  line-height: 19px;
  margin: 0px 4px;
`;

export const RequiredLabel = styled.p`
  color: #db3226;
  font-weight: normal;
  font-size: 11px;
  line-height: 19px;
  margin: 0px 4px;
`;

export const SettingContainer = styled.div`
  padding: 12px 16px;
  width: 100%;
  border: ${props => (props.isSelected ? ' 1px solid #0070CD' : '1px solid #e3e6ea')};
  background-color: ${props => (props.isSelected ? '#E6F1FF' : 'unset')};
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  margin: 4px 0px;
`;
export const SettingWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const SettingSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const SettingSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const SettingDescripContainer = styled.div`
  padding: 0px 12px;
`;
export const SettingDescripWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SettingDescripMain = styled.p`
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #484848;
`;
export const SettingDescripSub = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: #666666;
`;

export const CheckCircle = styled.div`
  border: ${props => (props.isSelected ? ' 1px solid #0070CD' : '1px solid #e3e6ea')};
  background-color: ${props => (props.isSelected ? '#0070CD' : 'unset')};
  box-sizing: border-box;
  border-radius: 40px;
  width: 22px;
  height: 22px;
  display: flex;
  flex-direction: revert;
  justify-content: center;
`;

export const extendExportButton = css`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  color: #484848;
  line-height: 20px;
`;

export const ExtendCounterStyle = css`
  margin-bottom: 7px;
`;
