import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icons from '@vezeeta/web-icons';
import { Icon, IconsStore } from '@vezeeta/web-components';
import { toIndiaDigits } from '@vezeeta/enterprise-utils/lib/Helpers';

import {
  MedicationDetails,
  MedicationDosage,
  MedicationImage,
  MedicationImageConatiner,
  MedicationItemContainer,
  MedicationItemWrapper,
  MedicationTitle,
  ItemOptionsContainer,
  IconContainer,
  OptionsListContainer,
  OptionsListItem,
  OptionsListWrapper,
} from './MedicationItem.style';

const MedicationItem = ({
  language,
  localization,
  index,
  item,
  removeMedicationFromPrescriptionList,
  editMedication,
  toggleDosageSelectionModal,
  display,
  listLength,
}) => {
  const {
    dosageCount,
    durationCount,
    productName,
    productShapeIconUrl,
    productKey,
    dosageName,
    durationName,
    frequencyName,
    instructionName,
    timeOfDayName,
    mainImageUrl,
  } = item;
  const [isOptionsListOpened, toggleOptionsList] = useState(false);

  const optionListListenerHandler = event => {
    const myElementToCheckIfClicksAreInsideOf = document.querySelector(`#item${index}`);
    if (!myElementToCheckIfClicksAreInsideOf.contains(event.target)) {
      toggleOptionsList(false);
    }
  };

  const addListener = () => {
    document.body.addEventListener('click', optionListListenerHandler, true);
  };

  const removeListener = () => {
    document.body.removeEventListener('click', optionListListenerHandler, true);
  };

  useEffect(() => {
    if (!display) addListener();
    return () => {
      if (!display) removeListener();
    };
  }, []);

  let timeOfDayString = '';

  if (timeOfDayName && timeOfDayName.length) {
    timeOfDayName.forEach((timeOfDayNameItem, idx) => {
      if (idx === 0) {
        timeOfDayString = timeOfDayNameItem;
      } else if (timeOfDayName.length === idx + 1) {
        timeOfDayString = `${timeOfDayString}, ${timeOfDayNameItem},`;
      } else {
        timeOfDayString = `, ${timeOfDayString}, ${timeOfDayNameItem}`;
      }
    });
  }
  const instructionNameString = instructionName ? `${instructionName},` : '';

  return (
    <MedicationItemContainer key={productKey} isLastItem={listLength - 1 === index}>
      <MedicationItemWrapper>
        <MedicationImageConatiner>
          <MedicationImage src={mainImageUrl || productShapeIconUrl} alt="" />
        </MedicationImageConatiner>
        <MedicationDetails>
          <MedicationTitle>{productName}</MedicationTitle>
          <MedicationDosage>{`${
            language === 'en' ? dosageCount : toIndiaDigits(String(dosageCount))
          } ${dosageName}, ${frequencyName}, ${timeOfDayString} ${instructionNameString} ${
            language === 'en' ? durationCount : toIndiaDigits(String(durationCount))
          } ${durationName}`}</MedicationDosage>
        </MedicationDetails>
        {!display && (
          <ItemOptionsContainer id={`item${index}`}>
            <IconContainer
              onClick={() => {
                toggleOptionsList(!isOptionsListOpened);
              }}
            >
              <Icon
                className="icon"
                icon={new IconsStore(Icons).getIcon('horizDots')}
                width={14}
                color="#484848"
              />
            </IconContainer>
            {isOptionsListOpened && (
              <OptionsListContainer language={language}>
                <OptionsListWrapper>
                  <OptionsListItem
                    onClick={() => {
                      toggleOptionsList(false);
                      editMedication(productKey);
                      toggleDosageSelectionModal(true);
                    }}
                  >
                    <p>{localization.edit}</p>
                  </OptionsListItem>
                  <OptionsListItem
                    onClick={() => {
                      toggleOptionsList(false);
                      removeListener();
                      removeMedicationFromPrescriptionList(productKey);
                    }}
                  >
                    <p>{localization.remove}</p>
                  </OptionsListItem>
                </OptionsListWrapper>
              </OptionsListContainer>
            )}
          </ItemOptionsContainer>
        )}
      </MedicationItemWrapper>
    </MedicationItemContainer>
  );
};

MedicationItem.propTypes = {
  language: PropTypes.string,
  localization: PropTypes.object,
  index: PropTypes.number,
  item: PropTypes.object,
  removeMedicationFromPrescriptionList: PropTypes.func,
  editMedication: PropTypes.func,
  toggleDosageSelectionModal: PropTypes.func,
  display: PropTypes.bool,
  listLength: PropTypes.array,
};

export default MedicationItem;
